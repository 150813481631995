import React, { useState, useEffect } from 'react';
import axios from '../../API/axios';
import ErrorToast from '../../common/Tosts/Errortost';

export default function Search() {
  const [query, setQuery] = useState('');
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [jumpToPage, setJumpToPage] = useState("");
  const [isErrorToast, setIsErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    if (query === '') {
      setData([]);
      setCurrentPage(1);
    }
  }, [query]);

  // Toast Error
  const showErrorToast = (message) => {
    setToastMessage(message);
    setIsErrorToast(true);

    setTimeout(() => {
      setIsErrorToast(false);
    }, 3000);
  };

  const handleSearch = async () => {
    if (!query) {
      showErrorToast("Please enter a domain name.");
      return;
    }

    try {
      const response = await axios.get('/api/v1/search', { params: { query } });
      if (response.data.message === "Domain not found") {
        showErrorToast("Domain not found.");
      } else {
        setData(response.data);
        setCurrentPage(1);  // Reset to first page on new search
      }
    } catch (error) {
      console.error('Error fetching search data:', error);
      showErrorToast("An error occurred while fetching data.");
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(data.length / 10)) {
      setCurrentPage(newPage);
    }
  };

  const handleJumpToPage = () => {
    const pageNumber = parseInt(jumpToPage, 10);
    if (isNaN(pageNumber) || pageNumber < 1 || pageNumber > totalPages) {
      // Show warning toast for invalid page number
      return;
    }
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(data.length / 10);
  const startIndex = (currentPage - 1) * 10;
  const currentPageData = data.slice(startIndex, startIndex + 10);

  // Handle Enter key press
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <section className='w-full h-full p-10 bg-white'>
      <div className={`flex flex-col space-y-12 items-center ${data.length ? 'justify-start' : 'justify-center'} h-full`}>
        <div className="flex items-center w-2/3 space-x-4 relative mx-auto text-gray-600 group">
          <input
            className="bg-white py-2.5 px-4 w-full pl-12 rounded-full outline-none border-2 border-primary-400"
            type="text"
            placeholder="Search Domain"
            autoFocus
            id='text'
            autoComplete='off'
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <svg
            className="h-5 w-5 fill-current absolute left-0 top-0 mt-[15px] ml-4 text-primary-500"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            stroke='1.5'
            viewBox="0 0 56.966 56.966"
            width="512px"
            height="512px"
          >
            <path
              d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
            />
          </svg>
          <button onClick={handleSearch} className='px-20 py-3 font-semibold bg-primary-500 text-white rounded-full font-inter'>Search</button>
        </div>
        {data.length > 0 && (
          <div>
            <div className="w-full rounded bg-white overflow-hidden shadow-default">
              <div className="overflow-x-auto w-full">
                <table className="text-sm min-w-full text-gray-500 text-center">
                  <thead className="text-[18px] w-full shadow-default bg-primary-600 text-white">
                    <tr>
                      <th className="px-2 py-2 w-[250px]">Domain Name</th>
                      <th className="px-2 py-2 w-[250px]">Date Detected</th>
                      <th className="px-2 py-2 w-[250px]">Registrar</th>
                      <th className="px-2 py-2 w-[250px]">Registration Date</th>
                      <th className="px-2 py-2 w-[250px]">Category</th>
                      <th scope="col" className="px-2 py-3"><span/></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPageData.map((item, index) => (
                      <tr key={index} className="bg-white border-b hover:bg-gray-50">
                        <td className="px-6 py-4 text-[16px] text-gray-900  whitespace-nowrap">{item.domain_name}</td>
                        <td className="px-6 py-4 text-[16px] text-gray-900  whitespace-nowrap">{item.date_detected}</td>
                        <td className="px-6 py-4 text-[16px] text-gray-900  whitespace-nowrap">{item.registrar}</td>
                        <td className="px-6 py-4 text-[16px] text-gray-900  whitespace-nowrap">{item.registration_date}</td>
                        <td className="px-6 py-4 text-[16px] text-gray-900  whitespace-nowrap">{item.category}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <hr />
            </div>

            {/* Pagination */}
            <div className="pagination-container flex flex-col sm:flex-row justify-between items-center pb-2 rounded-md py-2">
              <div className="pagination-buttons flex items-center">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`px-2 rounded border bg-white border-gray-200 py-2 hover:scale-110 translate- duration-150 ${currentPage === 1 ? "cursor-not-allowed" : "cursor-pointer"}`}
                >
                  <svg className="w-3 h-3 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                  </svg>
                </button>
                <span className="px-2 rounded border border-gray-200 py-[5px] mx-2">{currentPage} / {totalPages}</span>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className={`px-2 rounded border bg-white border-gray-200 py-2 hover:scale-110 translate- duration-150 ${currentPage === totalPages ? "cursor-not-allowed" : "cursor-pointer"}`}
                >
                  <svg className="w-3 h-3 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                  </svg>
                </button>
              </div>
              <div className="page-jump-input mt-2 sm:mt-0">
                <input
                  type="number"
                  className="border border-gray-300 rounded px-2 py-1"
                  value={jumpToPage}
                  onChange={(e) => setJumpToPage(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleJumpToPage();
                    }
                  }}
                  placeholder="Jump to page"
                />
              </div>
            </div>
          </div>
        )}
      </div>

        {/* Toast */}
       {isErrorToast && (
       <ErrorToast toastMessage={toastMessage}/>
         )}

    </section>
  );
}
