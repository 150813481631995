import React, { useState, useEffect, useCallback } from 'react';
import CountUp from 'react-countup';
import axios from '../../API/axios';

export default function Stats({ startDate, endDate }) {
  const [totalCount, setTotalCount] = useState(0);
  const [categoryCounts, setCategoryCounts] = useState({
    phishing: 0,
    malware: 0,
    botnet: 0,
    dga: 0
  });

  const normalizeCategory = (category) => {
    switch (category.toLowerCase()) {
      case 'phishing':
        return 'phishing';
      case 'malware':
        return 'malware';
      case 'botnet':
        return 'botnet';
      case 'dga':
        return 'dga';
      default:
        return category.toLowerCase();
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const totalResponse = await axios.get('/api/v1/count', {
        params: { start_date: startDate, end_date: endDate }
      });
      setTotalCount(totalResponse.data.total_count);

      const categoryResponse = await axios.get('/api/v1/count/category', {
        params: { start_date: startDate, end_date: endDate }
      });
      const counts = categoryResponse.data.reduce((acc, category) => {
        acc[normalizeCategory(category.category)] = category.count;
        return acc;
      }, { phishing: 0, malware: 0, botnet: 0, dga: 0 });
      setCategoryCounts(counts);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [startDate, endDate]); // add dependencies here

  useEffect(() => {
    fetchData();
  }, [fetchData]); // add fetchData to dependency array

  return (
    <div className='flex flex-col w-full gap-4'>
      <div className="flex flex-row w-full gap-4 text-gray-600">
        <div className="bg-white rounded-md shadow-default py-8 w-2/5">
          <div className="text-center space-y-5">
            <h3 className="text-4xl font-bold leading-none">
              <CountUp start={0} end={totalCount} delay={0} />
            </h3>
            <p className="text-[20px] font-semibold leading-none text-gray-600">Total Domains</p>
          </div>
        </div>
        <div className="bg-white rounded-md shadow-default py-8 w-2/5">
          <div className="text-center space-y-5">
            <h3 className="text-4xl font-bold leading-none">
              <CountUp start={0} end={categoryCounts.phishing} delay={0} />
            </h3>
            <p className="text-[20px] font-semibold leading-none text-gray-600">Phishing</p>
          </div>
        </div>
        <div className="bg-white rounded-md shadow-default py-8 w-2/5">
          <div className="text-center space-y-5">
            <h3 className="text-4xl font-bold leading-none">
              <CountUp start={0} end={categoryCounts.malware} delay={0} />
            </h3>
            <p className="text-[20px] font-semibold leading-none text-gray-600">Malware</p>
          </div>
        </div>
        <div className="bg-white rounded-md shadow-default py-8 w-2/5">
          <div className="text-center space-y-5">
            <h3 className="text-4xl font-bold leading-none">
              <CountUp start={0} end={categoryCounts.botnet} delay={0} />
            </h3>
            <p className="text-[20px] font-semibold leading-none text-gray-600">Botnet</p>
          </div>
        </div>
       </div>
       <div className="flex flex-row w-full gap-4 text-gray-600">
        <div className="bg-white rounded-md shadow-default py-8 w-2/5">
          <div className="text-center space-y-5">
            <h3 className="text-4xl font-bold leading-none">
              <CountUp start={0} end={categoryCounts.cryptojacking} delay={0} />
            </h3>
            <p className="text-[20px] font-semibold leading-none text-gray-600">Cryptojacking</p>
          </div>
        </div>
        <div className="bg-white rounded-md shadow-default py-8 w-2/5">
          <div className="text-center space-y-5">
            <h3 className="text-4xl font-bold leading-none">
              <CountUp start={0} end={categoryCounts.redteam} delay={0} />
            </h3>
            <p className="text-[20px] font-semibold leading-none text-gray-600">Red Team Tools</p>
          </div>
        </div>
        <div className="bg-white rounded-md shadow-default py-8 w-2/5">
          <div className="text-center space-y-5">
            <h3 className="text-4xl font-bold leading-none">
              <CountUp start={0} end={categoryCounts.c2} delay={0} />
            </h3>
            <p className="text-[20px] font-semibold leading-none text-gray-600">C2</p>
          </div>
        </div>
        <div className="bg-white rounded-md shadow-default py-8 w-2/5">
          <div className="text-center space-y-5">
            <h3 className="text-4xl font-bold leading-none">
              <CountUp start={0} end={categoryCounts.dga} delay={0} />
            </h3>
            <p className="text-[20px] font-semibold leading-none text-gray-600">DGA</p>
          </div>
        </div>
      </div>

    </div>
  );
}
